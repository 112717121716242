import React from 'react'
import './Footer.css'
import logo from '../../assets/logo.png'
import { MdAttachEmail } from "react-icons/md";
const Footer = () => {
  return (
    <div className='footer'>
        <div className="footer-top">
            <div className="footer-top-left">
                <img src={logo} alt="footer" />
                <p>I'm Fred Sseginda, FullStack developer based in Uganda</p>
            </div>
            <div className="footer-top-right">
                <div className="footer-email-input">
                   <MdAttachEmail/>
                   <input type="text" placeholder='Enter your email' />
                </div>
                <div className="footer-subscribe">
                    Subscribe
                </div>
            </div>
        </div>
        <hr />
            <div className="footer-bottom">
                <p className="footer-bottom-left">&copy;2024 Fred Sseginda. All rights reserved</p>
                <div className="footer-bottom-right">
                    <p>Terms of Services</p>
                    <p>Privacy Policy</p>
                    <p>Connect with me</p>
                </div>
            </div>
    </div>
  )
}

export default Footer