const Services_Data=[
    {
        s_no:"01",
        s_name:"Web Design",
        s_desc:"Web development is the process of building, programing and deploying web based systems such as websites..."
    },
    {
        s_no:"02",
        s_name:"Embedded System Design",
        s_desc:"Embedded development is the process of building, programing and deploying embedded systems..."
    },
    {
        s_no:"03",
        s_name:"Graphic Design",
        s_desc:"This refers to the designing of different graphics like flyers, business cards, logos, Barners and others..."
    }
]
export default Services_Data;