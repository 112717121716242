import React from 'react'
import './About.css'
import fred1 from '../../assets/fred1.jpg'

const AboutMe = () => {
  return (
    <div id='about' className='about'>
        <div className="about-title">
            <h1>About Me</h1>
        </div>
        <div className="about-sections">
            <div className="about-left">
                <img src={fred1} alt="Fred" style={{ width: '500px', height: '600px' }}/>
            </div>
            <div className="about-right">
                <div className="about-para">
                    <p>I'm Fred Sseginda, FullStack developer based in Uganda</p>
                    <p>With 2+ years experience as a professional software development, I have acquired the skills and knowledge necessary to make your project a success. 
                        I enjoy every step of the development process, from discussion and collaboration.</p>
                </div>
                <div className="about-skills">
                    <div className="about-skill">
                        <p>HTML & CSS</p><hr style={{width:'60%'}}/>
                    </div>
                    <div className="about-skill">
                        <p>JAVA</p><hr style={{width:'80%'}}/>
                    </div>
                    <div className="about-skill">
                        <p>SPRINGBOOT</p><hr style={{width:'70%'}}/>
                    </div>
                    <div className="about-skill">
                        <p>LARAVEL</p><hr style={{width:'60%'}}/>
                    </div>
                    <div className="about-skill">
                        <p>SQL</p><hr style={{width:'90%'}}/>
                    </div>
                    <div className="about-skill">
                        <p>REACT JS</p><hr style={{width:'60%'}}/>
                    </div>
                    <div className="about-skill">
                        <p>C</p><hr style={{width:'50%'}}/>
                    </div>
                </div>
            </div>
        </div>
        <div className="about-achievements">
                <div className="about-achievement">
                    <h1>2+</h1>
                    <p>YEARS OF EXPERIENCE</p>
                </div>
                <hr/>
                <div className="about-achievement">
                    <h1>8+</h1>
                    <p>PROJECTS COMPLETED</p>
                </div>
                <hr/>
                <div className="about-achievement">
                    <h1>5+</h1>
                    <p>HAPPY CLIENTS</p>
                </div>
            </div>
    </div>
  )
}

export default AboutMe