import React from 'react'
import './Contact.css'
import { MdAttachEmail } from "react-icons/md";
import { FaPhoneVolume } from "react-icons/fa6";
import { IoLocation } from "react-icons/io5";
import ReactGA from 'react-ga';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
const Contact = () => {
    const onSubmit = async (event) => {
        event.preventDefault();
        ReactGA.event({category: 'Contact', action: 'submitForm'});
        const formData = new FormData(event.target);
    
        formData.append("access_key", "12d2c80a-fb8c-4186-b430-20464644c1a2");
    
        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);
    
        const res = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          },
          body: json
        }).then((res) => res.json());
        if (res.success) {
            ReactGA.event({category: 'Contact', action: 'submitFormSuccess'});
          toast.success("Email sent successfully")
          event.target.reset();
        }
      };

  return (
    <div id='contact' className='contact'>
        <ToastContainer />
        <div className="contact-title">
            <h1>Contact Me</h1>
            <img src="" alt="" />
        </div>
        <div className="contact-section">
            <div className="contact-left">
                <h1>Let's Talk</h1>
                <p>I'm currently availabe for anything related to software development. Connect with me via and call in to my account.</p>
                <div className="contact-details">
                    <div className="contact-detail">
                        <MdAttachEmail className='img'/><p>fredsseginda70@mail.com</p>
                    </div>
                    <div className="contact-detail">
                        <FaPhoneVolume className='img'/><p>+256 709105749/+256 763640912</p>
                    </div>
                    <div className="contact-detail">
                        <IoLocation className='img'/><p>Kampala, Uganda</p>
                    </div> 
                </div>
            </div>
            <form onSubmit={onSubmit}  className="contact-right">
                <input type="hidden" value='12d2c80a-fb8c-4186-b430-20464644c1a2'/>
                <label htmlFor="">Your Name</label>
                <input type="text" placeholder='Enter your name' name='name' required/>
                <label htmlFor="">Your Email</label>
                <input type="email" placeholder='Enter your email' name='email' required/>

                <label htmlFor="">Message</label>
                <textarea name="message" rows='8' placeholder='Enter your message' required/>
                
                <button className='contact-submit' type='submit'>Send</button>
            </form>
        </div>
    </div>
  )
}

export default Contact