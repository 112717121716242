import React from 'react'
import './Work.css'
import mywork_data from '../../assets/mywork_data'
import { FaArrowRight } from "react-icons/fa";

const MyWork = () => {
  return (
    <div id='mywork' className='mywork'>
        <div className="mywork-title">
            <h1>My latest work</h1>
            <img src="" alt="" />
        </div>
        <div className="mywork-container">
            {mywork_data.map((data,index)=>{
                return <div key={index} className="mywork-format">
                    <img src={data.w_img} alt="" />
                </div>
            })}
        </div>
        <div className="mywork-showmore">
            <p>Show more</p>
            <FaArrowRight />
        </div>
    </div>
  )
}

export default MyWork
