import React, { useRef, useState, useEffect } from 'react'
import './Navbar.css'
import logo from '../../assets/logo.png'
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { IoMdMenu } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import ReactGA from 'react-ga';

const Navbar = () => {
  const [menu,setMenu]= useState("home");
  const menuRef=useRef();
  useEffect(()=>{
    ReactGA.pageview(window.location.pathname);
  })

  const openMenu =()=>{
    menuRef.current.style.right="0";
    ReactGA.event({category: 'Navbar', action: 'openMenu'});
  }
  const closeMenu =()=>{
    menuRef.current.style.right="-350px";
    ReactGA.event({category: 'Navbar', action: 'closeMenu'});
  }

  return (
    <div className='navbar'>
        <img src={logo} alt="Logo" />
        <IoMdMenu onClick={openMenu} className='nav-mob-open'/>
        <ul ref={menuRef} className="nav-menu">
        <IoMdClose onClick={closeMenu} className='nav-mob-close'/>
            <li><AnchorLink className='anchor-link' href='#home'>
              <p onClick={()=>setMenu("home")}  
                style={menu === "home" ? {
                borderBottom: '4px solid',
                borderImage: 'linear-gradient(90deg, #DA7C25 0.36%, #B923E1 102.36%) 1'
              } : {}}>Home</p></AnchorLink></li>
            <li><AnchorLink className='anchor-link' offset={50} href='#about'>
              <p onClick={()=>setMenu("about")}
                 style={menu === "about" ? {
                  borderBottom: '4px solid',
                  borderImage: 'linear-gradient(90deg, #DA7C25 0.36%, #B923E1 102.36%) 1'
                } : {}}
                >About Me</p></AnchorLink></li>
            <li><AnchorLink className='anchor-link' offset={50} href='#services'><p onClick={()=>setMenu("services")}
               style={menu === "services" ? {
                borderBottom: '4px solid',
                borderImage: 'linear-gradient(90deg, #DA7C25 0.36%, #B923E1 102.36%) 1'
              } : {}}
              >Services</p></AnchorLink></li>
            <li><AnchorLink className='anchor-link' offset={50} href='#mywork'><p onClick={()=>setMenu("mywork")}
               style={menu === "mywork" ? {
                borderBottom: '4px solid',
                borderImage: 'linear-gradient(90deg, #DA7C25 0.36%, #B923E1 102.36%) 1'
              } : {}}
              >Portfolio</p></AnchorLink></li>
            <li><AnchorLink className='anchor-link' offset={50} href='#contact'><p onClick={()=>setMenu("contact")}
               style={menu === "contact" ? {
                borderBottom: '4px solid',
                borderImage: 'linear-gradient(90deg, #DA7C25 0.36%, #B923E1 102.36%) 1'
              } : {}}
              >Contact</p></AnchorLink></li>
        </ul>
        <div className="nav-connect"><AnchorLink className='anchor-link' offset={50} href='#contact'>Connect with Me</AnchorLink></div>
    </div>
  )
}

export default Navbar