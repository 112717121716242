import Inquiry from './Inquiry.png'
import Security from './Security.png'
import final from './final.jpg'
import employee from './employee.png'
import Abrishpartner from './Abrishpartner.jpg'
import Brie_Sticker from  './Brie-Sticker.jpg'
import haji_ramadhan from './haji-ramadhan.jpg'
const mywork_data=[
    {
        w_no:1,
        w_name:"Web Design",
        w_img:Inquiry
    },
    {
        w_no:2,
        w_name:"Web Design",
        w_img:Security
    },
    {
        w_no:3,
        w_name:"Web Design",
        w_img:employee
    },
    {
        w_no:4,
        w_name:"Embedded Development",
        w_img:final
    },
    {
        w_no:5,
        w_name:"Graphic Design",
        w_img:Abrishpartner
    },
    {
        w_no:6,
        w_name:"Graphic Design",
        w_img:Brie_Sticker
    },
    {
        w_no:7,
        w_name:"Graphic Design",
        w_img:haji_ramadhan
    }
]

export default mywork_data;