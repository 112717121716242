import React, { useEffect } from 'react'
import './Main.css'
import fred1 from '../../assets/fred1.jpg'
import AnchorLink from 'react-anchor-link-smooth-scroll';
import ReactGA from 'react-ga';

const Main = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  })
  return (
    <div id='home' className='main'>
        <img src={fred1} alt="Profile" className="profile-image" />
        <h1><span>I'm Fred Sseginda,</span> FullStack developer based in Uganda</h1>
        <p>I am Fullstack developer based in uganda with 2+ years of experience. 
            I have worked with some companies like <b>Pahappa Ltd Ntinda</b>, <b>WellDone Agro</b> and <b>Vibrica Solutions</b>. </p>
            <div className="main-action">
                <div className="main-connect"><AnchorLink className='anchor-link' offset={50} href='#contact'>Connect with me</AnchorLink></div>
                <div className="main-resume">My resume</div>
            </div>
    </div>
  )
}

export default Main